import axios from "axios";
const {
    ADD_BILLSRECEIVE,
    UPDATE_BILLSRECEIVE,
    GET_ALL_BILLSRECEIVE,
    DESTROY_BILLSRECEIVE,
    DOWNLOAD_BILLSRECEIVE,
    FIND_BILLSRECEIVE,
    AUTO_COMPLETE,
    VIEW_BILLS_RECEIVE,
    GET_BILLS_RECEIVE_BY_STATUS,
    BILLS_RECEIVE_REVERSAL,
    AUTO_COMPLETE_BILLS_TO_RECEIVE_BY_STATUS,
    GET_ALL_BILLS_RECEIVE_BY_DATE,
    DOWNLOAD_BILLSRECEIVE_MULTIPLE,
    GET_ALL_BILLSRECEIVE_BY_ENTERPRISE,
    AUTO_COMPLETE_BY_ENTERPRISE,
} = require("./actions/bills_receive");
const {
    SPLICE_ERRO,

} = require('./actions/alert_api')

const moduleBills_receive = {
    state: () => ({
        BillReceive: Object(),
        autoCompleteBillsToReceive: Object(),
        viewBillsReceive: Object(),
        titleReversal: Object(),
    }),

    mutations: {

        GET_ALL_BILLSRECEIVE: (state, payload) => {

            if (payload.data.length > 0) {
                payload.data.forEach(e => {
                    if (e.client.type_people == "LegalPeople") {
                        e.client.people.name = e.client.people.legalpeople.fantasyName;
                    } else {
                        e.client.people.name = e.client.people.physicalpeople.name;
                    }
                });
            }
            state.BillReceive = { ...payload };
        },

        GET_ALL_BILLSRECEIVE_BY_ENTERPRISE: (state, payload) => {
            if(payload.data) {
                if (payload.data.length > 0) {
                    payload.data.forEach(e => {
                        if (e.client.type_people == "LegalPeople") {
                            e.client.people.name = e.client.people.legalpeople.fantasyName;
                        } else {
                            e.client.people.name = e.client.people.physicalpeople.name;
                        }
                    });
                }
                state.BillReceive = { ...payload };
            } else {
                state.BillReceive = []
            }
        },

        GET_BILLS_RECEIVE_BY_STATUS: (state, payload) => {
            if (payload.data.length > 0) {
                payload.data.forEach(e => {
                    if (e.client.type_people == "LegalPeople") {
                        e.client.people.name = e.client.people.legalpeople.fantasyName;
                    } else {
                        e.client.people.name = e.client.people.physicalpeople.name;
                    }
                });
            }
            state.BillReceive = { ...payload };
        },
        
        AUTO_COMPLETE_BILLS_TO_RECEIVE_BY_STATUS: (state, payload) => {
            if (payload.data.length > 0) {
                payload.data.forEach(e => {
                    if (e.client.type_people == "LegalPeople") {
                        e.client.people.name = e.client.people.legalpeople.fantasyName;
                    } else {
                        e.client.people.name = e.client.people.physicalpeople.name;
                    }
                });
            }
            state.BillReceive = { ...payload };
        },

        GET_ALL_BILLS_RECEIVE_BY_DATE: (state, payload) => {
            if (payload.data.length > 0) {
                payload.data.forEach(e => {
                    if (e.client.type_people == "LegalPeople") {
                        e.client.people.name = e.client.people.legalpeople.fantasyName;
                    } else {
                        e.client.people.name = e.client.people.physicalpeople.name;
                    }
                });
            }
            state.BillReceive = { ...payload };
        },

        

        ADD_BILLSRECEIVE: (state, newBillReceive) => {
            if (newBillReceive.client.type_people === "LegalPeople") {
                newBillReceive.client.people.name = newBillReceive.client.people.legalpeople.fantasyName;
              } else {
                newBillReceive.client.people.name = newBillReceive.client.people.physicalpeople.name;
              }

            state.BillReceive.data.push(newBillReceive);
        },

        UPDATE_BILLSRECEIVE(state, editCard_adm) {
            let auxState = { ...state.BillReceive }
            if (editCard_adm.client.type_people == "LegalPeople") {
                editCard_adm.client.people.name = editCard_adm.client.people.legalpeople.fantasyName;
            } else {
                editCard_adm.client.people.name = editCard_adm.client.people.physicalpeople.name;
            }
            auxState.data.Update(editCard_adm)
            state.BillReceive = { ...auxState }
        },


        DESTROY_BILLSRECEIVE: (state, id) => {
            let auxState = [...state.BillReceive.data];
            let index = auxState.indexOf(
                auxState.find(value => value.id === id),
            );
            if (index >= 0) {
                auxState.splice(index, 1);
            }
            state.BillReceive = [...auxState];
        },
        DOWNLOAD_BILLSRECEIVE: (state, payload) => {
            let auxData = state.BillReceive
            auxData.data.forEach((e) => {
                if (e.id === payload.billstoReceive_id) {
                    e.title_balance = payload.value_low - e.title_balance
                    if (e.title_balance === 0) { e.status = 'Baixado' }
                }
            })
            state.BillReceive = { ...auxData }
        },
        DOWNLOAD_BILLSRECEIVE_MULTIPLE: (state, payload) => {
            let auxData = state.BillReceive
            auxData.data.forEach((e) => {
                if (e.id === payload.billstoReceive_id) {
                    e.title_balance = payload.value_low - e.title_balance
                    if (e.title_balance === 0) { e.status = 'Baixado' }
                }
            })
            state.BillReceive = { ...auxData }
        },
        FIND_BILLSRECEIVE: (state, payload) => {
            state.BillReceive = payload
        },

        BILLS_RECEIVE_REVERSAL: (state, payload) => {
            state.titleReversal = payload
        },

        AUTO_COMPLETE: (state, payload) => {
            if (payload.data.length > 0) {
                payload.data.forEach(e => {
                    if (e.client.type_people == "LegalPeople") {
                        e.client.people.name = e.client.people.legalpeople.fantasyName;
                    } else {
                        e.client.people.name = e.client.people.physicalpeople.name;
                    }
                });
            }
            state.BillReceive = { ...payload };
        },
        AUTO_COMPLETE_BY_ENTERPRISE: (state, payload) => {
                if (payload.data.length > 0) {
                    payload.data.forEach(e => {
                        if (e.client.type_people == "LegalPeople") {
                            e.client.people.name = e.client.people.legalpeople.fantasyName;
                        } else {
                            e.client.people.name = e.client.people.physicalpeople.name;
                        }
                    });
                }
                state.BillReceive = { ...payload };
        },
        VIEW_BILLS_RECEIVE: (state, payload) => {
            state.viewBillsReceive = payload
        },

        GET_REPORT_BILLSRECEIVE: (state, payload) => {
            state.BillReceive = payload
        }
    },

    actions: {
        VIEW_BILLS_RECEIVE: function(context, payload){
            context.commit(VIEW_BILLS_RECEIVE, payload)
        },
        AUTO_COMPLETE: function (context, payload) {
            try {
                axios.post('/billstoreceive/autocomplete', payload).then(response => {
                    context.commit(AUTO_COMPLETE, response.data[0])
                })
            } catch (error) {
                throw new Error(error)
            }
        },

        AUTO_COMPLETE_BY_ENTERPRISE: function (context, payload) {
            try {
                axios.post('/billstoreceive/autocompleteByEnterprise', payload).then(response => {
                    context.commit(AUTO_COMPLETE_BY_ENTERPRISE, response.data)
                })
            } catch (error) {
                throw new Error(error)
            }
        },

        async GET_REPORT_BILLSRECEIVE({ commit }, payload) {      
            try {        
               const response = await axios.post("catalog/billstoreceive", payload)        
               commit(SPLICE_ERRO, {
                   alert: 
                   response.data.msg, type: 'S'
               })        
               return response      
           } catch (error) {
                commit(SPLICE_ERRO, { alert: error.response.data.error,  type: 'E' })     
            } 
       },

        async FIND_BILLSRECEIVE({ commit }, payload) {
            await axios.post('billstoreceive/find', {
                date_initial: payload.date_initial,
                date_final: payload.date_final,
                enterprise_id: payload.enterprise_id,
                page: payload.page,
                paginate: payload.paginate,
                limit: payload.limit,
            }).then((response) => {
                commit(FIND_BILLSRECEIVE, response.data[0])
                commit(SPLICE_ERRO, {
                    alert: "Título Baixado com sucesso!",
                    type: 'S'
                })
            })
        },
        async DOWNLOAD_BILLSRECEIVE({ commit }, payload) {
            await axios.post('billstoreceive/downloadTitle', payload).then((response) => {
                commit(DOWNLOAD_BILLSRECEIVE, response.data[0])
                commit(SPLICE_ERRO, {
                    alert: "Título Baixado com sucesso!",
                    type: 'S'
                })
            })
        },

        async DOWNLOAD_BILLSRECEIVE_MULTIPLE({ commit }, payload) {
            await axios.post('billstoreceive/multipleTitleDownload', payload).then((response) => {
                commit(SPLICE_ERRO, {
                    alert: "Título Baixado com sucesso!",
                    type: 'S'
                })
                commit(DOWNLOAD_BILLSRECEIVE_MULTIPLE, response.data[0])
            })
        },

        async BILLS_RECEIVE_REVERSAL({commit}, payload) {
            await axios.post('billstoreceive/reversalBillstoReceive', payload)
            .then((response) => {
                commit(BILLS_RECEIVE_REVERSAL, response.data[0])
                commit(SPLICE_ERRO, {
                    alert: "Título Estornado com sucesso!",
                    type: 'S'
                })
            })
        },

        GET_ALL_BILLSRECEIVE({
            commit
        }, payload) {
            axios.post("billstoreceive/index", { page: payload.page, limit: payload.limit, paginate: payload.paginate }).then((response) => {
                commit(GET_ALL_BILLSRECEIVE, response.data[0]);
            });
        },

        GET_ALL_BILLSRECEIVE_BY_ENTERPRISE({
            commit
        }, payload) {
            axios.post("billstoreceive/getAllByEnterprise", { page: payload.page, limit: payload.limit, paginate: payload.paginate, enterprise_id: payload.enterprise_id }).then((response) => {
                commit(GET_ALL_BILLSRECEIVE_BY_ENTERPRISE, response.data);
            });
        },

        GET_BILLS_RECEIVE_BY_STATUS({
            commit
        }, payload) {
            axios.post("billstoreceive/indexByStatus", {status: payload.status, page: payload.page, limit: payload.limit, paginate: payload.paginate }).then((response) => {
                commit(GET_BILLS_RECEIVE_BY_STATUS, response);
            });
        },
        
        AUTO_COMPLETE_BILLS_TO_RECEIVE_BY_STATUS({
            commit
        }, payload) {
            axios.post("/billstoreceive/autocompleteByStatus", payload).then((response) => {
                commit(AUTO_COMPLETE_BILLS_TO_RECEIVE_BY_STATUS, response);
            });
        },

        GET_ALL_BILLS_RECEIVE_BY_DATE({
            commit
        }, payload) {
            axios.post("/billstoreceive/getAllByDueDate", payload).then((response) => {
                commit(GET_ALL_BILLS_RECEIVE_BY_DATE, response);
            });
        },

        async ADD_BILLSRECEIVE({
            commit
        }, payload) {
            return new Promise((resolve, reject) => {

                axios.post("billstoreceive/store", payload).then((response) => {
                    resolve()
                    commit(ADD_BILLSRECEIVE, response.data[0]);
                    commit(SPLICE_ERRO, {
                        alert: 'Cadastrado com Sucesso!',
                        type: 'S'
                    })
                }).catch(() => {
                    reject()
                })

            })
        },

        async UPDATE_BILLSRECEIVE({
            commit
        }, payload) {
            return new Promise((resolve, reject) => {

                axios.post("billstoreceive/update", payload).then((response) => {
                    resolve()
                    commit(UPDATE_BILLSRECEIVE, response.data[0]);
                    commit(SPLICE_ERRO, {
                        alert: 'Cadastrado com Sucesso!',
                        type: 'S'
                    })
                }).catch(() => {
                    reject()
                })
            })
        },

        async DESTROY_BILLSRECEIVE({
            commit
        }, payload) {

            axios.post("billstoreceive/delete", {
                id: payload.id
            }).then((response) => {
                commit(DESTROY_BILLSRECEIVE, payload.id);
                commit(SPLICE_ERRO, {
                    alert: 'Deletado com Sucesso!',
                    type: 'S'
                })
            })
        },
    },
    getters: {
        getBillReceive: (state) => ({
            ...state.BillReceive
        }),
        getAutoCompleteBillsToReceive: (state) => state.autoCompleteBillsToReceive,
        getViewBillsReceive: (state) => state.viewBillsReceive
    },
};

export default moduleBills_receive;